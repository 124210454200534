<div class="row spacing-30" style="max-width: 1400px" *ngIf="company">
  <div class="col-6 d-flex flex-column">
    <div class="d-flex spacing-30">
      <h2 style="color: white" class="m-0">Assistant Details</h2>
    </div>
    <div class="card" style="background-color: #0D0F11">
      <div class="card-body" style="padding: 50px">
        <div class="row p-0">
          <div class="col-12 p-0 ">
            <div class="row pb-3">
              <div class="col-6 p-0">
                <label class="f-18 f-bold f-secondary spacing-15">{{ "companySettings.info.orgNr" | translate }}</label>
                <p style="color: #909699">{{company.organisation_number}}</p>
              </div>
              <div class="col-6 p-0">
                <label class="f-18 f-bold f-secondary spacing-15">{{ "companySettings.info.address" | translate }}</label>
                <p style="color: #909699">{{company.address}}, {{company.postal_code}} {{company.city}}</p>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col-6 p-0">
                <label class="f-18 f-bold f-secondary spacing-15">{{ "companySettings.info.phone" | translate }}</label>
                <p style="color: #909699">{{company.phone}}</p>
              </div>
              <div class="col-6 p-0">
                <label class="f-18 f-bold f-secondary spacing-15">{{ "companySettings.info.email" | translate }}</label>
                <p style="color: #909699">{{company.email}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6 d-flex flex-column">
    <div class="row">
      <div class="d-flex spacing-30">
        <h2 style="color: white" class="m-0">{{ "companySettings.settings.title" | translate }}</h2>
      </div>
    </div>
    <div class="card h-100" style="background-color: #0D0F11">
      <div class="card-body" style="padding: 50px">
        <div class="row pb-3">
          <div class="col-12 ">
            <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; width: 100%" (click)="editDefaultFiles()">Common files</div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-12 ">
            <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; width: 100%" (click)="editWordBank()">Word Bank</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="row" style="max-width: 1400px;" *ngIf="employees">
  <div class="col-12">
    <div class="row spacing-30">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <h2 style="color: white">{{"companySettings.employees.title" | translate}}</h2>
      </div>
    </div>
    <div class="row mb-2 me-1 d-flex justify-content-end">
      <div class="col pe-0 d-flex justify-content-between spacing-30">
        <div class="col-10">
          <p style="color: #909699" class="mb-0">Here is a list of all employees that have access to your company in MasterEmployee. You can see this list because you are an administrator. When adding a new user you can decide which role the new user should have, and you can also decide what access the user should have. Roles and access can also be edited after the user has been invited.</p>
        </div>
        <div class="d-flex align-items-end">
          <button class="btn btn-primary f-14" style="height: 40px" (click)="inviteEmployees()">
            <i class="fa fa-plus me-2"></i>
            {{"companySettings.employees.inviteBtn" | translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="row ">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <table class="table rounded-table table-hover">
              <thead>
              <tr>
                <th scope="col" class="ps-3">{{"companySettings.employees.name" | translate}}</th>
                <th scope="col">{{"companySettings.employees.phone" | translate}}</th>
                <th scope="col">{{"companySettings.employees.email" | translate}}</th>
                <th scope="col">{{"companySettings.employees.role" | translate}}</th>
                <th scope="col">{{"companySettings.employees.updated" | translate}}</th>
                <th scope="col" class="pe-3"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let employee of employees" class="table-row-hover">
                <td class="cursor-pointer" (click)="editEmployee(employee)">
                  <div class="ps-3">
                    {{ employee.first_name }} {{ employee.last_name}}
                  </div>
                </td>
                <td class="cursor-pointer" (click)="editEmployee(employee)">{{ employee.phone }}</td>
                <td class="cursor-pointer" (click)="editEmployee(employee)">{{ employee.email }}</td>
                <td class="cursor-pointer" (click)="editEmployee(employee)">{{ employee.role_name }}</td>
                <td class="cursor-pointer" (click)="editEmployee(employee)">
                  {{ formatTimeDMYHHMM(employee.updated_at) ? formatTimeDMYHHMM(employee.updated_at) : formatTimeDMYHHMM(employee.created_at)}}
                </td>
                <td>
                  <div class="pe-3">
                    <i class="fa-regular fa-trash-can fa-lg pe-3" (click)="removeEmployeeFromEntity(employee)"></i>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
