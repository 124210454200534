<div class="row spacing-30" style="max-width: 1400px; min-height: 500px" *ngIf="assistantGroup">
  <div class="col-4 d-flex flex-column">
    <div class="d-flex spacing-30">
      <h2 style="color: white" class="m-0">Assistant Details</h2>
      <i class="fa fa-star fa-2x ms-2 cursor-pointer"
         style="color: #ED622E"
         *ngIf="userFavoritesIds.includes(assistantGroup.assistant_group_id)"
         (click)="removeFromFavorites(assistantGroup)"></i>
      <i class="fa fa-regular fa-star fa-2x ms-2 cursor-pointer"
         style="color: white"
         *ngIf="!userFavoritesIds.includes(assistantGroup.assistant_group_id)"
         (click)="addToFavorites(assistantGroup)"></i>
    </div>
    <div class="card " style="min-height: 500px">
      <div class="content d-flex flex-column h-100" style="margin: 30px">
        <div class="row spacing-30">
          <div class="col-12 d-flex align-items-center">
            <div class="icon-circle me-3">
              <img src="../../favicon.ico" alt="avatar 1" style="width: 30px; height: 30px;">
            </div>
            <p style="color: white" class="m-0 f-20">{{assistantGroup.assistant_group_name}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="row spacing-30">
              <div class="col-12 d-flex align-items-center">
                <div class="icon-circle me-3">
                  <i class="fa fa-solid fa-lock" style="color: #ED622E"></i>
                </div>
                <p style="color: white" class="m-0 f-16">Accessibility: </p>
                <span class="label private f-16" *ngIf="assistantGroup.private">{{ "assistants.table.label.private" | translate }}</span>
                <span class="label public f-16" *ngIf="!assistantGroup.private">{{ "assistants.table.label.public" | translate }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="assistantGroup.reference">
          <div class="col-12">
            <div class="row spacing-30">
              <div class="col-12 d-flex align-items-center">
                <div class="icon-circle me-3">
                  <i class="fa fa-solid fa-hashtag" style="color: #ED622E"></i>
                </div>
                <p style="color: white" class="m-0 f-16 me-2">Reference: </p>
              </div>
              <div class="col-12" style="margin-left: 65px; width: 80%">
                <p style="color: #909699" class="m-0 f-16">{{assistantGroup.reference}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="assistantGroup.assistant_group_description">
          <div class="col-12">
            <div class="row spacing-30">
              <div class="col-12 d-flex align-items-center">
                <div class="icon-circle me-3">
                  <i class="fa fa-solid fa-comment" style="color: #ED622E"></i>
                </div>
                <p style="color: white" class="m-0 f-16 me-2">Description: </p>
              </div>
              <div class="col-12" style="margin-left: 65px; width: 80%">
                <p style="color: #909699" class="m-0 f-16">{{assistantGroup.assistant_group_description}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row spacing-30" style="margin-right: 30px; margin-left: 30px; min-height: 40px">
          <div class="d-flex justify-content-between w-100 p-0" *ngIf="assistantGroup.private">
            <div class="btn btn-primary me-3" style="background-color: #5e656c; border-color: #5e656c" (click)="shareAssistantGroup()" *ngIf="canEdit && assistantGroup.private">{{ "assistants.details.share.assistant" | translate }}</div>
            <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="editAssistantGroup()" *ngIf="canEdit">{{ "assistants.details.edit.assistant" | translate }}</div>
          </div>
          <div class="d-flex justify-content-end w-100" *ngIf="!assistantGroup.private">
            <div class="btn btn-primary me-3" style="background-color: #5e656c; border-color: #5e656c" (click)="shareAssistantGroup()" *ngIf="canEdit && assistantGroup.private">{{ "assistants.details.share.assistant" | translate }}</div>
            <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="editAssistantGroup()" *ngIf="canEdit">{{ "assistants.details.edit.assistant" | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-8">
    <div class="row spacing-30">
      <div class="col d-flex justify-content-between align-items-center">
        <h2 style="color: white" class="spacing-30 m-0">Dialogues</h2>
        <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="createThread()">
          {{ "assistants.details.createThreadBtn" | translate }}
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!loadingThreads" style="max-height: 500px; overflow: auto">
      <div class="col-12" >
        <table class="table rounded-table table-hover" >
          <thead>
          <tr>
            <th scope="col" class="ps-3">Assistant type</th>
            <th scope="col">{{ "assistants.chooseAssistant.table.topic" | translate }}</th>
            <th scope="col">{{ "assistants.table.created" | translate }}</th>
            <th scope="col" class="pe-3"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="threads.length === 0">
            <td colspan="4" class="text-center" style="color: white; font-weight: bold;">
              {{ "assistants.details.noThreads" | translate }}
            </td>
          </tr>
          <tr *ngFor="let thread of threads">
            <td class="cursor-pointer p-0" (click)="goToThread(thread)">
              <div class="ps-3">
                {{ thread.assistant.assistant_type_name }}
              </div>
            </td>
            <td class="cursor-pointer" (click)="goToThread(thread)">{{ thread.topic }}</td>
            <td class="cursor-pointer" (click)="goToThread(thread)">{{ formatTimeDMYHHMM(thread.created_at) }}</td>
            <td class="cursor-pointer p-0" (click)="deleteThread(thread)">
              <div class="pe-3">
                <i class="fa-regular fa-trash-can fa-lg"></i>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row pt-2" *ngIf="loadingThreads">
      <div class="d-flex justify-content-center">
        <div class="fa fa-regular fa-spinner-third fa-spin fa-5x"></div>
      </div>
    </div>
  </div>
</div>

<div class="row" style="max-width: 1400px;" *ngIf="assistantGroup">
  <div class="col-12">
    <div class="row spacing-30">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <h2 style="color: white">Reports</h2>
        <div>
          <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="createReport()">Create report</div>
        </div>
      </div>
    </div>
    <div class="row spacing-30">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <table class="table rounded-table table-hover">
              <thead>
              <tr>
                <th scope="col" class="ps-3">Report</th>
                <th scope="col">Assistant</th>
                <th scope="col">Question set</th>
                <th scope="col">Status</th>
                <th scope="col">Progress percentage</th>
                <th scope="col">Created</th>
                <th scope="col" class="me-3"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngIf="loadingReports">
                <td colspan="12" class="text-center" style="color: white; font-weight: bold;">
                  <div class="d-flex justify-content-center">
                    <div class="fa fa-regular fa-spinner-third fa-spin fa-5x"></div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="reports.length === 0 && !loadingReports">
                <td colspan="12" class="text-center" style="color: white; font-weight: bold;">
                  No reports generated
                </td>
              </tr>
              <tr *ngFor="let report of reports">
                <td class="cursor-pointer ps-0" [title]="report.report_name" style="max-width: 400px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" (click)="viewReport(report)">
                  <div class="ps-3">
                    {{ report.report_name }}
                  </div>
                </td>
                <td class="cursor-pointer" (click)="viewReport(report)">
                  {{ report.assistant.assistant_type_name}}
                </td>
                <td class="cursor-pointer" (click)="viewReport(report)">
                  {{ report.template_name}}
                </td>
                <td class="cursor-pointer" (click)="viewReport(report)">
                  <div *ngIf="report.status == 0">
                    In progress
                  </div>
                  <div *ngIf="report.status == 1">
                    Completed
                  </div>
                  <div *ngIf="report.status == 2">
                    Failed
                  </div>
                </td>
                <td class="cursor-pointer" (click)="viewReport(report)">
                  {{ report.progress_percentage }} %
                </td>
                <td class="cursor-pointer" (click)="viewReport(report)">{{ formatTimeDMYHHMM(report.created_at) }}</td>
                <td class="cursor-pointer" style="text-align: right;">
                  <div class="pe-3">
                    <i class="fa-regular fa-trash-can fa-lg pe-3" (click)="deleteReport(report)"></i>
                    <i class="fa fa-regular fa-eye" (click)="viewReport(report)"></i>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row" style="max-width: 1400px;" *ngIf="assistantGroup">
  <div class="col-12">
    <div class="row spacing-30">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <h2 style="color: white">Documents</h2>
        <div>
          <div class="btn btn-primary me-2" style="background-color: #5e656c; border-color: #5e656c" (click)="addDocuments()">{{ "assistants.details.addDocumentBtn" | translate }}</div>
          <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="createDocument()">{{ "assistants.details.createDocumentBtn" | translate }}</div>
        </div>
      </div>
    </div>
      <div class="row ">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <table class="table rounded-table table-hover">
                <thead>
                  <tr>
                    <th scope="col" class="ps-3">{{ "assistants.details.files.table.fileName" | translate }}</th>
                    <th scope="col">{{ "assistants.details.files.table.fileSize" | translate }}</th>
                    <th scope="col">{{ "assistants.details.files.table.ocr" | translate }}</th>
                    <th scope="col">{{ "assistants.details.files.table.vector_store" | translate }}</th>
                    <th scope="col">{{ "assistants.details.files.table.s3" | translate }}</th>
                    <th scope="col">{{ "assistants.details.files.table.created" | translate }}</th>
                    <th scope="col" class="me-3"></th>
                  </tr>
                </thead>
                <tbody>
                <tr *ngIf="assistantGroup.assistants[0].files.length === 0">
                  <td colspan="12" class="text-center" style="color: white; font-weight: bold;">
                    {{ "assistants.details.noFiles" | translate }}
                  </td>
                </tr>
                <tr *ngFor="let file of assistantGroup.assistants[0].files">
                  <td class="cursor-pointer ps-0" [title]="file.file_name" style="max-width: 400px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" (click)="viewFile(file)">
                    <div class="ps-3">
                      {{ file.file_name }}
                    </div>
                  </td>
                  <td class="cursor-pointer" (click)="viewFile(file)">
                    {{ file.file_size / (1024 * 1024) | number: '1.2-2' }} MB
                  </td>
                  <td class="cursor-pointer" (click)="viewFile(file)">
                    <i class="fa fa-regular fa-check ms-2" *ngIf="file.ocr"></i>
                    <i class="fa fa-regular fa-spinner fa-spin ms-2" *ngIf="!file.ocr"></i>
                  </td>
                  <td class="cursor-pointer" (click)="viewFile(file)">
                    <i class="fa fa-regular fa-check ms-2" *ngIf="file.vector_store"></i>
                    <i class="fa fa-regular fa-spinner fa-spin ms-2" *ngIf="!file.vector_store"></i>
                  </td>
                  <td class="cursor-pointer" (click)="viewFile(file)">
                    <i class="fa fa-regular fa-check ms-2" *ngIf="file.s3"></i>
                    <i class="fa fa-regular fa-spinner fa-spin ms-2" *ngIf="!file.s3"></i>
                  </td>
                  <td class="cursor-pointer" (click)="viewFile(file)">{{ formatTimeDMYHHMM(file.created_at) }}</td>
                  <td class="cursor-pointer" style="text-align: right;">
                    <div class="pe-3">
                      <i class="fa-regular fa-trash-can fa-lg pe-3" (click)="removeFile(file)"></i>
                      <i class="fa fa-regular fa-download" (click)="downloadFileObject(file)"></i>
                      <i class="fa fa-regular fa-eye ps-3" *ngIf="file.file_extension == '.pdf' ?? file.file_extension == '.PDF'" (click)="viewFile(file)"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
