<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">Common files</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">Here you can create group of files that can be added to Master Assistants on creation for ease of use. First create a file group / category and then add the files in the group.</p>
      </div>
    </div>
    <div class="row spacing-20" *ngFor="let group of defaultFileGroups; let index = index">
      <div class="test p-2" style="border: 1px solid #495055; border-radius: 8px">
        <div class="row cursor-pointer" [class.spacing-30]="isSecondRowVisible">
          <div class="col d-flex justify-content-between" (click)="toggleSecondRow(index)" *ngIf="expandedIndex !== index">
            <div class="d-flex">
              <p class="f-16 f-bold label-class mb-0">{{group.default_file_group_name}}</p>
              <p class="f-14 label-class mb-0 ps-3" style="color: #495055">{{group.default_file_group_description}}</p>
            </div>
            <!-- Add an expand/collapse icon in the first row -->
            <div class="col-6 d-flex align-items-center justify-content-end" >
              <i
                class="fa"
                [ngClass]="{'fa-chevron-down': !isSecondRowVisible, 'fa-chevron-up': isSecondRowVisible}"
                style="cursor: pointer; color: white">
              </i>
            </div>
          </div>
          <div class="col w-100 d-flex justify-content-between spacing-30" *ngIf="expandedIndex === index">
            <div class="row w-100 p-0">
              <div class="col-5">
                <label class="f-16 f-bold label-class spacing-15" *ngIf="expandedIndex === index">Group name</label>
                <input
                  class="form-control flex-grow-1 input-class"
                  type="text"
                  placeholder="Group name"
                  [(ngModel)]="group.default_file_group_name"
                  (change)="onChange(group)"
                  [title]="group.default_file_group_name"
                >
              </div>
              <div class="col-6">
                <label class="f-16 f-bold label-class spacing-15" *ngIf="expandedIndex === index">Group description</label>
                <input
                  class="form-control flex-grow-1 input-class"
                  type="text"
                  placeholder="Group description"
                  [(ngModel)]="group.default_file_group_description"
                  (change)="onChange(group)"
                  [title]="group.default_file_group_description"
                >
              </div>
              <div class="col d-flex justify-content-end" (click)="toggleSecondRow(index)">
                <i
                  class="fa"
                  [ngClass]="{'fa-chevron-down': !isSecondRowVisible, 'fa-chevron-up': isSecondRowVisible}"
                  style="cursor: pointer; color: white">
                </i>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-2" *ngIf="expandedIndex === index">
          <div class="col-12">
            <div class="row" *ngIf="group.files.length == 0 && expandedIndex === index">
              <div class="col d-flex justify-content-center">
                <p class="f-16" style="color: #909699">No files added</p>
              </div>
            </div>
            <div *ngIf="group.files.length > 0 && expandedIndex === index">
              <div class="row spacing-15" *ngFor="let file of group.files">
                <div class="col-6 d-flex justify-content-between align-items-center">
                  <p class="f-16 m-0" style="color: #909699">{{file.file_name}}</p>
                </div>
                <div class="col-2 d-flex justify-content-between align-items-center">
                  <p class="f-16 m-0" style="color: #909699">{{ file.file_size / (1024 * 1024) | number: '1.2-2' }} MB</p>
                </div>
                <div class="col-3 d-flex justify-content-between align-items-center">
                  <p class="f-16 m-0" style="color: #909699">{{formatTimeDMYHHMM(file.created_at)}}</p>
                </div>
                <div class="col-1 d-flex justify-content-between align-items-center">
                  <div class="col-1 d-flex align-items-center justify-content-center" *ngIf="expandedIndex === index">
                    <div class="col-1 d-flex align-items-center">
                      <i class="fa-regular fa-bucket cursor-pointer" style="color: #909699" (click)="removeFile(file)"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="row d-flex justify-content-center w-75" *ngIf="uploadFiles && expandedIndex === index">
                <div class="col-8 dropzone" style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding-top: 40px;">
                  <input type="file" #fileDropRef id="fileDropRef" multiple (change)="onFileSelected($event, group)" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                  <div style="display: flex; flex-direction: column; align-items: center;">
                    <i class="fa fa-regular fa-cloud-arrow-up fa-2x pb-2" style="color: #ED622E;"></i>
                    <div style="display: flex; justify-content: center; align-items: center;">
                      <p class="fw-semibold f-16 me-1 cursor-pointer" style="color: #ED622E; margin: 0;">Click to upload</p>
                      <p class="f-16" style="color: #909699; margin: 0;">or drag and drop</p>
                    </div>
                    <p class="f-16" style="color: #909699; margin: 0;">pdf, PDF, docx</p>
                  </div>
                </div>
                <div class="row pt-3" *ngFor="let file of selectedFiles">
                  <div class="col-12 d-flex ms-2 align-items-center">
                    <i style="color: #909699"  class="fa fa-file-pdf fa-2x me-2"></i>
                    <h5 style="color: #909699"  class="m-0">{{file.name}}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-2" *ngIf="expandedIndex === index">
              <div class="d-flex align-items-center">
                <i class="fa fa-plus me-2" style="color: #ED622E"></i>
                <div class="btn ps-0" style="color: #ED622E" (click)="uploadFiles = !uploadFiles">Add file</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <i class="fa fa-plus me-2" style="color: #ED622E"></i>
      <div class="btn ps-0" style="color: #ED622E" (click)="addGroup()">Add group</div>
    </div>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 35px">
<!--    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white">-->
<!--      {{ "companySettings.wordBank.saveBtn" | translate }}-->
<!--    </div>-->
  </div>
</div>
