<div class="test">
  <div class="row cursor-pointer d-flex align-items-center my-0" *ngIf="!isSecondRowVisible">
    <div class="col d-flex justify-content-between align-items-center spacing-30 input-container my-0">
      <i class="fa-solid fa-bars me-2 label-class"></i>
      <div class="w-100 position-relative">
        <label
          class="f-16 f-bold me-2 label-class spacing-15"
          *ngIf="isSecondRowVisible">
          {{ 'questions.displayName.label' | translate }}
        </label>
        <input
          class="form-control flex-grow-1 input-class"
          type="text"
          placeholder="{{ 'questions.displayName.placeholder' | translate }}"
          [(ngModel)]="question.display_name"
          (change)="onChange()"
          [title]="question.display_name"
          style="border: 1px solid #495055; background-color: #101214"
        >
        <i
          class="fa label-class position-absolute right-icon"
          [ngClass]="{'fa-chevron-down': !isSecondRowVisible, 'fa-chevron-up': isSecondRowVisible}"
          style="cursor: pointer;"
          (click)="toggleSecondRow()"
        >
        </i>
      </div>
    </div>
  </div>

  <div class="row d-flex align-items-center spacing-15 m-0" *ngIf="isSecondRowVisible">
    <div class="col spacing-30 m-0 p-3 w-100" style="border: 1px solid #495055; border-radius: 8px">
      <div class="row spacing-30 w-100" *ngIf="isSecondRowVisible">
        <div class="col">
          <div class="d-flex justify-content-between align-items-center">
            <label
              class="f-16 f-bold me-2 label-class spacing-15"
              *ngIf="isSecondRowVisible">
              {{ 'questions.displayName.label' | translate }}
            </label>
            <i
              class="fa label-class"
              [ngClass]="{'fa-chevron-down': !isSecondRowVisible, 'fa-chevron-up': isSecondRowVisible}"
              style="cursor: pointer; margin-left: auto; margin-right: -25px;"
              (click)="toggleSecondRow()"
            ></i>
          </div>
          <input
            class="form-control flex-grow-1 input-class"
            type="text"
            placeholder="{{ 'questions.displayName.placeholder' | translate }}"
            [(ngModel)]="question.display_name"
            (change)="onChange()"
            [title]="question.display_name"
            style=""
          >
        </div>
        <div class="" style="position: absolute; right: 90px; top: 50%; transform: translateY(-50%);" *ngIf="isImprovingQuestion">
          <div class="fa fa-regular fa-spinner-third fa-spin fa-2x" style="color: black"></div>
        </div>
      </div>
      <div class="row spacing-30 w-100" *ngIf="isContextVisible && isSecondRowVisible">
        <label class="f-16 f-bold label-class spacing-15">{{'questions.context.label' | translate}}</label>
        <div class="col ">
          <textarea
            class="form-control input-class"
            rows="5"
            placeholder="{{'questions.contextPlaceholder' | translate}}"
            [(ngModel)]="question.context"
            (change)="onChange()"
            [title]="question.context">
          </textarea>
        </div>
        <div class="" style="position: absolute; right: 90px; top: 50%; transform: translateY(-50%);" *ngIf="isImprovingQuestion">
          <div class="fa fa-regular fa-spinner-third fa-spin fa-2x" style="color: black"></div>
        </div>
      </div>
      <div class="row w-100" *ngIf="isSecondRowVisible">
        <label class="f-16 f-bold label-class spacing-15">{{'questions.question.label' | translate}}</label>
        <div class="col spacing-15">
          <textarea
            class="form-control input-class"
            rows="5"
            placeholder="{{'questions.questionPlaceholder' | translate}}"
            [(ngModel)]="question.question"
            (change)="onChange()"
            [title]="question.question">
          </textarea>
        </div>
        <div class="d-flex align-items-center" *ngIf="isSecondRowVisible && !isContextVisible">
          <i class="fa fa-plus me-2" style="color: #ED622E"></i>
          <div class="btn ps-0" style="color: #ED622E" (click)="toggleContextVisibility()" >{{ "questions.addQuestionContextBtn" | translate }}</div>
        </div>
      </div>
    </div>
    <div class="col-1">
      <div class="d-flex justify-content-center align-items-center">
        <i class="fa fa-trash-can fa-lg cursor-pointer" style="color: white" (click)="deleteQuestion()"></i>
      </div>
    </div>
  </div>
</div>
