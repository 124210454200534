<div>
  <div class="row" style="margin-bottom: 100px; margin-top: 30px">
    <div class="col-12 position-relative">
      <img src="assets/images/img.png" class="welcome-img img-fluid">
      <div class="img-overlay">
        <div class="d-flex flex-column justify-content-center align-items-center h-100 text-center">
          <h1 class="text-white mb-3" *ngIf="user">Hi {{user.first_name}} {{user.last_name}}</h1>
          <div class="btn btn-primary cursor-pointer" (click)="showReleaseNotes()">
        <span class="cursor-pointer" style="z-index: 1000">
          <i class="fa fa-arrows-rotate"></i> New Release (v1.2.0) 02.01.2025 - See more here
        </span>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="col-5">-->
<!--      <div class="card">-->

<!--      </div>-->
<!--    </div>-->
  </div>
  <div class="row" style="height: 620px">
    <div class="col-6">
      <div class="card">
        <div class="row m-3">
          <div class="col">
            <h3 style="color: white">Recent Dialogues</h3>
          </div>
        </div>
        <div class="row m-3">
          <div class="col" *ngIf="!loadingThreads">
            <div class="row d-flex justify-content-center align-items-center" *ngIf="recentThreads.length == 0">
              <div class="col d-flex justify-content-center">
                <span class="m-0 label-class fw-bold" style="color: #495055">No recent dialogues</span>
              </div>
            </div>
            <div class="thread" *ngFor="let thread of recentThreads; let index = index">
              <div class="row-box d-flex align-items-center">
                <div class="icon-circle">
                  <i class="fa fa-regular fa-comment fa-lg" style="color: #ED622E"></i>
                </div>
                <p class="m-0 label-class ms-3" style="max-width: 380px" *ngIf="thread.topic">
                  {{thread.assistant.assistant_name}} - {{thread.topic}}
                </p>
                <p class="m-0 label-class ms-3" style="max-width: 380px" *ngIf="!thread.topic">
                  {{thread.assistant.assistant_name}}
                </p>
                <!-- Spacer to push the next two elements to the end -->
                <div class="ms-auto d-flex align-items-center cursor-pointer" (click)="goToThread(thread)">
                  <p class="m-0 me-3" style="color: #ED622E;">Continue to dialogue</p>
                  <i class="fa fa-regular fa-arrow-up-right fa-lg" style="color: #ED622E"></i>
                </div>
              </div>
              <hr *ngIf="index < recentThreads.length - 1" style="color: white">
            </div>
          </div>
          <div class="col d-flex justify-content-center align-items-center" *ngIf="loadingThreads">
            <i class="fa fa-spinner fa-spin fa-4x" style="color: #495055"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <div class="row m-3">
          <div class="col">
            <h3 style="color: white">Recently Created Assistants</h3>
          </div>
        </div>
        <div class="row m-3">
          <div class="col" *ngIf="!loadingAssistants">
            <div class="row d-flex justify-content-center align-items-center" *ngIf="recentAssistants.length == 0">
              <div class="col d-flex justify-content-center">
                <span class="m-0 label-class fw-bold" style="color: #495055">No recently created Assistant</span>
              </div>
            </div>
            <div class="thread" *ngFor="let assistant of recentAssistants let index = index">
              <div class="row-box d-flex align-items-center">
                <div class="icon-circle">
                  <img src="../../favicon.ico" alt="avatar 1" style="width: 30px; height: 30px;">
                </div>
                <p class="m-0 label-class ms-3 text-truncate" style="max-width: 430px" *ngIf="assistant.reference">
                  {{assistant.reference}} - {{assistant.assistant_group_name}}
                </p>
                <p class="m-0 label-class ms-3 text-truncate" style="max-width: 430px" *ngIf="!assistant.reference">
                  {{assistant.assistant_group_name}}
                </p>
                <!-- Spacer to push the next two elements to the end -->
                <div class="ms-auto d-flex align-items-center cursor-pointer">
                  <p class="m-0 me-3" style="color: #ED622E;" (click)="createThread(assistant)">Start dialogue</p>
                  <i class="fa fa-regular fa-arrow-up-right fa-lg" style="color: #ED622E"></i>
                </div>
              </div>
              <hr *ngIf="index < recentAssistants.length - 1" style="color: white">
            </div>
          </div>
          <div class="col d-flex justify-content-center align-items-center" *ngIf="loadingAssistants">
            <i class="fa fa-spinner fa-spin fa-4x" style="color: #495055"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
