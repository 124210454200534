<div class="modal-content" style="max-height: 800px; overflow: auto">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"templates.edit.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">Here you can edit or add questions to the question sets. Context is the describe the context of the question an the question field is for the question it self. Try not to ask two or more questions in the same question for the best results. </p>      </div>
    </div>
    <form [formGroup]="editTemplateForm" (change)="editTemplate()">
      <div class="row spacing-30">
        <div class="col-6">
          <label class="f-16 f-bold label-class spacing-15 fw-semibold">{{'templates.edit.templateNameLabel' | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control input-class"
              formControlName="templateName"
              placeholder="{{'templates.edit.templateName' | translate}}"
            >
          </div>
          <span *ngIf="editTemplateForm.get('templateName')?.invalid && editTemplateForm.get('templateName')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
        <div class="col-6">
          <label class="f-16 f-bold label-class spacing-15">{{'templates.edit.templateDescriptionLabel' | translate}}</label>
          <input
            type="text"
            class="form-control input-class"
            formControlName="templateDescription"
            placeholder="{{'templates.edit.templateDescriptionPlaceholder' | translate}}"
          >
        </div>
      </div>
      <div class="row spacing-30" *ngIf="userId && template.created_by?.user_id == userId">
        <div class="col-8">
          <div class="form-check form-switch">
            <input class="form-check-input label-class" type="checkbox" role="switch" id="flexSwitchCheckDefault" formControlName="private">
            <label class="form-check-label label-class" for="flexSwitchCheckDefault">{{ "templates.createNew.private.label" | translate }}</label>
          </div>
        </div>
      </div>
    </form>
    <hr style="color: #495055" *ngIf="template.private">
    <div class="row spacing-20" *ngIf="template.private">
      <div class="col-12">
        <label class="form-check-label label-class f-20 spacing-20">{{'templates.accessRelation.label' | translate}}</label>
        <div class="box">
          <div class="row spacing-15" *ngFor="let relation of template.access_relations">
            <div class="col-4">
              <span style="color: white">{{getUserData(relation.user_id)?.full_name}}</span>
            </div>
            <div class="col-2">
              <span style="color: #5e656c">{{relation.access_level.access_level_name}}</span>
            </div>
            <div class="col-4" *ngIf="canEdit">
              <i class="fa-regular fa-bucket" style="color: #5e656c" (click)="removeRelation(relation.relation_id)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3 pb-3" *ngIf="template.private && canEdit" >
      <div class="col-8 d-flex justify-content-between">
        <div class="dropdown me-3">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span *ngIf="selectedOption">{{ selectedOption.full_name }}</span>
            <span *ngIf="!selectedOption">{{'assistants.details.share.select' | translate }}</span>
          </button>
          <ul class="dropdown-menu input-dropdown-class p-0" aria-labelledby="dropdownMenuButton">
            <input
              type="text"
              class="form-control input-class"
              placeholder="Search..."
              [(ngModel)]="searchTerm"
            />
            <li *ngFor="let option of filteredOptions()" style="cursor: pointer">
              <a
                class="dropdown-item label-class"
                (click)="selectOption(option)"
              >{{ option.full_name }}</a
              >
            </li>
          </ul>
        </div>
        <select class="form-select" *ngIf="selectedOption" aria-label="Default select example" [(ngModel)]="selectedAccessLevel">
          <option value="99" selected>{{'assistants.details.share.accessLevel.placeholder' | translate}}</option>
          <option value="0">{{"assistants.details.share.accessLevel.owner" | translate}}</option>
          <option value="1">{{"assistants.details.share.accessLevel.edit" | translate}}</option>
          <option value="2">{{"assistants.details.share.accessLevel.view" | translate}}</option>
        </select>
        <div class="btn btn-primary ms-3" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; min-width: 100px" *ngIf="selectedOption && selectedAccessLevel" (click)="addRelation()">
          {{ "assistants.details.share.save" | translate }}
        </div>
      </div>
    </div>
    <hr style="color: #495055">
    <label class="form-check-label label-class spacing-15 f-20 fw-semibold">{{ "questions.title" | translate }}</label>
<!--    <div *ngFor="let question of questions; let i = index" class="pb-2">-->
<!--      <app-question [question]="question" [questionAnswerTypes]="questionAnswerTypes" (questionChange)="handleQuestionChange($event)" (questionDelete)="handleQuestionDelete($event)"></app-question>-->
<!--    </div>-->
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      @for (question of questions; track question) {
        <div class="example-box spacing-30" cdkDrag>
          <app-question
            [question]="question"
            [questionAnswerTypes]="questionAnswerTypes"
            (questionChange)="handleQuestionChange($event)"
            (questionDelete)="handleQuestionDelete($event)">
          </app-question>
        </div>
      }
    </div>
    <div class="d-flex align-items-center">
      <i class="fa fa-plus me-2" style="color: #ED622E"></i>
      <div class="btn ps-0" style="color: #ED622E" (click)="addQuestion()" >Add question</div>
    </div>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 35px">
    <div class="btn btn-primary" (click)="save()">{{"global.save" | translate}}</div>
  </div>
</div>
